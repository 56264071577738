import { z } from 'zod';
import { AddressSchema } from './address.schema';
import { zPhoneNumber } from './utils.schema';

//REGISTER BUSINESS
const businessName = z.string().trim().min(1, {
  message: 'form.required',
});

const businessEmail = z.string().trim().email({ message: 'form.invalidEmail' });

const businessPhoneNumber = z.string().trim().min(11, {
  message: 'form.required',
});

const businessCategory = z.string().trim().min(1, {
  message: 'form.required',
});

export const RegisterBusinessSchema = z
  .object({
    businessName,
    businessEmail,
    businessPhoneNumber,
    businessAddress: AddressSchema.shape.address,
    businessCategory,
  })
  .superRefine(({ businessAddress }, ctx) => {
    const { value } = businessAddress;
    if (!value.label.trim()) {
      ctx.addIssue({
        code: 'custom',
        message: 'form.required',
        path: ['businessAddress'],
      });
    }
  });

export type RegisterBusinessSchemaType = z.infer<typeof RegisterBusinessSchema>;

// REGISTER
const firstName = z.string().trim().min(1, {
  message: 'form.required',
});
const lastName = z.string().trim().min(1, {
  message: 'form.required',
});
const phoneNumber = zPhoneNumber();
const email = z.string().email({ message: 'form.invalidEmail' });

const password = z
  .string()
  .min(8, { message: 'form.minPassword' })
  .max(50, { message: 'form.maxPassword' })
  .refine((password) => /[A-Z]/.test(password), {
    message: 'form.uppercasePassword',
  })
  .refine((password) => /[!@#$%^&*(),.?":{}|<>_[\]]/.test(password), {
    message: 'form.specialPassword',
  })
  .refine((password) => /[0-9]/.test(password), {
    message: 'form.numberPassword',
  });
const confirmPassword = z
  .string()
  .min(8, { message: 'form.minPassword' })
  .max(50, { message: 'form.maxPassword' })
  .refine((password) => /[A-Z]/.test(password), {
    message: 'form.uppercasePassword',
  })
  .refine((password) => /[!@#$%^&*(),.?":{}|<>_[\]]/.test(password), {
    message: 'form.specialPassword',
  })
  .refine((password) => /[0-9]/.test(password), {
    message: 'form.numberPassword',
  });
const verificationToken = z.string().refine((value) => /^\d{4}$/.test(value), {
  message: 'form.invalidCode',
});

// const role = z.nativeEnum(UserRole_Enum, {
//   invalid_type_error: 'form.invalidRole',
// });

export const RegisterSchema = z
  .object({
    firstName,
    lastName,
    phoneNumber,
    email,
    password,
    confirmPassword,
  })
  .superRefine(({ confirmPassword, password }, ctx) => {
    if (confirmPassword !== password) {
      ctx.addIssue({
        code: 'custom',
        message: 'form.passwordsDidNotMatch',
        path: ['confirmPassword'],
      });
    }
  });

export type RegisterSchemaType = z.infer<typeof RegisterSchema>;

export const RegisterCustomerSchema = z
  .object({
    firstName,
    lastName,
    phoneNumber: zPhoneNumber({ optional: true }),
    email,
    password,
    confirmPassword,
  })
  .superRefine(({ confirmPassword, password }, ctx) => {
    if (confirmPassword !== password) {
      ctx.addIssue({
        code: 'custom',
        message: 'form.passwordsDidNotMatch',
        path: ['confirmPassword'],
      });
    }
  });

export type RegisterCustomerSchemaType = z.infer<typeof RegisterCustomerSchema>;

// REGISTER VERIFICATION CODE
const signUpVerificationToken = z
  .string()
  .min(1, {
    message: 'form.required',
  })
  .refine((value) => /^\d{4}$/.test(value), {
    message: 'form.invalidCode',
  });

export const RegisterVerificationTokenSchema = z.object({
  signUpVerificationToken,
});

export type RegisterVerificationTokenSchemaType = z.infer<
  typeof RegisterVerificationTokenSchema
>;

// LOGIN
export const LoginSchema = z.object({
  email,
  password: z
    .string()
    .min(8, {
      message: 'form.minPassword',
    })
    .max(50, {
      message: 'form.maxPassword',
    }),
});

export type LoginSchemaType = z.infer<typeof LoginSchema>;

// FORGOT PASSWORD
export const ForgotPasswordSchema = z.object({ email });

export type ForgotPasswordSchemaType = z.infer<typeof ForgotPasswordSchema>;

// CODE VALIDATION

export const CodeValidationSchema = z.object({
  email,
  verificationToken,
});

export type CodeValidationSchemaType = z.infer<typeof CodeValidationSchema>;

// CHANGE PASSWORD

export const ChangePasswordSchema = z
  .object({
    password,
    confirmPassword,
  })
  .superRefine(({ confirmPassword, password }, ctx) => {
    if (confirmPassword !== password) {
      ctx.addIssue({
        code: 'custom',
        message: 'form.passwordsDidNotMatch',
        path: ['confirmPassword'],
      });
    }
  });

export type ChangePasswordSchemaType = z.infer<typeof ChangePasswordSchema>;

/* BuildingManagers */
export const BuildingInformationSchema = z
  .object({
    name: z.string().trim().min(1, { message: 'form.required' }),
    type: z.string().trim().min(1, { message: 'form.required' }),
  })
  .merge(AddressSchema)
  .superRefine(({ address }, ctx) => {
    const { value, options = [] } = address;
    if (
      !options.find(
        ({ label, coordinates }) =>
          label === value?.label &&
          JSON.stringify(coordinates) === JSON.stringify(value?.coordinates),
      )
    ) {
      ctx.addIssue({
        code: 'custom',
        message: 'form.selectAddressFromOptions',
        path: ['address'],
      });
    }
  });

export type BuildingInformationSchemaType = z.infer<
  typeof BuildingInformationSchema
>;
